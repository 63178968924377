<template>
  <div class="ui-drawer">
    <div class="drawer-wrap">
      <!-- <img
        @click="switchDrawer"
        :src="`${rootUrl}/img/takumi-hirano_001.png`"
        class="drawer-button"
        alt="メニュー"
      /> -->
      <div
        class="btn-trigger btn05"
        @click="hamburgerSet"
        :class="{
          active: active === true,
          '': active === false,
        }"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div></div>
      <div
        class="drawer-menu"
        :class="{
          'open-drawer': openDrawer === true,
          'close-drawer': openDrawer === false,
        }"
      >
        <div class="drawer-header"></div>
        <div @click="hamburgerSet" class="drawer-body">
          <div class="drawer-item" @click="top">
            <!-- <router-link to="/">トップ</router-link> -->
            <span>トップ</span>
          </div>
          <div class="drawer-item" v-scroll-to="'#about'">
            <!-- <router-link to="/zeimukomon/">税務顧問・記帳代行</router-link> -->
            <span>ヒーリングサロン aimeについて</span>
          </div>
          <div class="drawer-item" v-scroll-to="'#user'">
            <!-- <router-link to="/zeimukomon/">税務顧問・記帳代行</router-link> -->
            <span>こんな人が利用しています</span>
          </div>
          <div class="drawer-item" v-scroll-to="'#introduction'">
            <!-- <router-link to="/zeimukomon/">税務顧問・記帳代行</router-link> -->
            <span>セラピスト紹介</span>
          </div>
          <div class="drawer-item" v-scroll-to="'#menu'">
            <!-- <router-link to="/zeimukomon/">税務顧問・記帳代行</router-link> -->
            <span>セラピーメニュー</span>
          </div>
          <div class="drawer-item" v-scroll-to="'#access'">
            <!-- <router-link to="/zeimukomon/">税務顧問・記帳代行</router-link> -->
            <span>ご利用方法・アクセス</span>
          </div>
          <div class="drawer-item" v-scroll-to="'#voice'">
            <!-- <router-link to="/zeimukomon/">税務顧問・記帳代行</router-link> -->
            <span>お客様の声</span>
          </div>
          <div class="drawer-item" v-scroll-to="'#question'">
            <!-- <router-link to="/zeimukomon/">税務顧問・記帳代行</router-link> -->
            <span>よくある質問</span>
          </div>
          <div class="drawer-item" v-scroll-to="'#flow'">
            <!-- <router-link to="/zeimukomon/">税務顧問・記帳代行</router-link> -->
            <span>セラピーの申し込みの流れ</span>
          </div>
          <div class="drawer-item" v-scroll-to="'#contact'">
            <!-- <router-link to="/zeimukomon/">税務顧問・記帳代行</router-link> -->
            <span>ご予約・お問い合わせ</span>
          </div>
        </div>
      </div>
    </div>
    <div
      @click="hamburgerSet"
      class="back-gray"
      :class="{ 'back-gray-show': openDrawer === true }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "UiDrawer",
  data() {
    return {
      openDrawer: false,
      active: false,
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
      // return this.$store.getters.rt;
    },
  },
  methods: {
    switchDrawer() {
      this.openDrawer = this.openDrawer === false ? true : false;
    },
    hamburgerButton() {
      this.active = this.active === false ? true : false;
    },
    hamburgerSet() {
      this.switchDrawer();
      this.hamburgerButton();
    },
        top() {
        this.$scrollTo("#app", 500, { easing: "ease" });
        // alert('トップページ');
    },
  },
};
</script>

<style lang="scss" scoped>
$width: 300px;
.drawer-wrap {
  .drawer-button {
    width: 45px;
    cursor: pointer;
  }
  .drawer-menu {
    transition: all 1s 0s ease;
    position: fixed;
    top: 0;
    background-color: rgba(var(--rgb-sub),0.9);
    // padding: 5px 10px 50px 10px;//普通のリストは復活
    box-sizing: border-box;
    z-index: 999;
    width: $width;
    height: 100vh;

    .drawer-body {
      // background-color: var(--beige);
      padding-top: 50px;
      .drawer-item {
        text-align: initial;
        transition: all 1s;
        padding: 18px 10px;
        // background-color: white;
        transition: all 0.5s; //普通のリストは消す
        position: relative; //普通のリストは消す
        z-index: 2; //普通のリストは消す
        @media screen and (max-width: 767px) {
          padding: 5px 10px;
        }
        &:hover {
          cursor: pointer;
        }
        span {
          margin-left: 10px;
        }

        a,
        span {
          text-decoration: none;
          color: var(--black);
          position: relative;
          display: inline-block;
          &::after {
            position: absolute;
            bottom: -5px;
            left: 0;
            content: "";
            width: 100%;
            height: 2px;
            background: #858585;
            transform: scale(0, 1);
            transform-origin: left top;
            transition: transform 0.5s;
          }
          &:hover::after {
            transform: scale(1, 1);
          }
        }
      }
    }
  }
}
.open-drawer {
  // display: initial;
  opacity: 1;
  transform: translateX(-$width);
  right: -$width;
}
.close-drawer {
  // display: none;
  opacity: 0;
  transition: opacity 3s linear 2s;
  transform: translateX($width);
  right: -$width;
}

.back-gray {
  display: none;
  position: fixed;
  z-index: 998;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--back01);
  opacity: 0.4;
}
.back-gray-show {
  display: initial;
}

.btn-trigger {
  position: relative;
  width: 30px;
  height: 25px;
  cursor: pointer;
  z-index: 1000;
  @media screen and (max-width: 1024px) {
    //  width: 40px;
    //  height: 35px;
  }
}
.btn-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--black);
  border-radius: 3px;
}
.btn-trigger,
.btn-trigger span {
  display: inline-block;
  transition: all 0.5s;
  box-sizing: border-box;
}
.btn-trigger span:nth-of-type(1) {
  top: 0;
}
.btn-trigger span:nth-of-type(2) {
  top: 11px;//2本目の高さ
}
.btn-trigger span:nth-of-type(3) {
  bottom: 0;
}
.btn-trigger span:nth-of-type(1) {
  animation: menu-bar01 0.75s forwards;
}
@keyframes menu-bar01 {
  0% {
    transform: translateY(10px) rotate(45deg);
  }
  50% {
    transform: translateY(10px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
@keyframes menu-bar02 {
  0% {
    transform: translateY(-10px) rotate(-45deg);
  }
  50% {
    transform: translateY(-10px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
.btn-trigger.active span:nth-of-type(1) {
  animation: active-menu-bar01 0.75s forwards;
}
@keyframes active-menu-bar01 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(10px) rotate(0);
  }
  100% {
    transform: translateY(10px) rotate(45deg);
  }
}
.btn-trigger.active span:nth-of-type(2) {
  opacity: 0;
}
.btn-trigger.active span:nth-of-type(3) {
  animation: active-menu-bar03 0.75s forwards;
}
@keyframes active-menu-bar03 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-20px) rotate(0);
  }
  100% {
    transform: translateY(-12px) rotate(-45deg);//✕になった時の角度
  }
}
</style>