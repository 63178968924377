import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  setTitle,
  setDescription
} from '@/mixins/index.js'


import Sample from '../views/Sample.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Sample,
    // redirect: '/sample01',
    meta: {
      title: 'ヒーリングサロン aime',
      description: 'ヒーリングサロン aime（アイム）では、セラピスト自身が大病を克服した経験を活かし、お客様の心と身体のバランスを整えながら、お悩みや問題を解決に導きます。丁寧なカウンセリングの上で、レイキヒーリングやヒプノセラピーを組み合わせながら、お客様にとって最適なセッション・セラピーをご提供させていただきます。なお、ご利用はオンラインでも可能です。対面でのセラピーは吉祥寺のレンタルルームで行います。どうぞお気軽にご相談・お問い合わせください。'
    }
  },
  //ワイルドカードのルートをリダイレクトする
  {
    path: '*',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition == true) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  store.dispatch("setRootUrl");

next();

  // if (to.path.slice(-1) === "/") {
  //   next(to.path.slice(0, -1));
  // } else {
  //   next();
  // }
});

export default router
