<template>
  <div class="sample-menu space-btm-200p wrap">
    <UiHeading :h="'2'" :type="'E2'" :headingText="headingText" />

    <div class="cover">
      <SampleMenuMenuBox
        v-for="menuBoxListData in menuBoxList"
        :key="menuBoxListData.id"
        :title="menuBoxListData.title"
        :text="menuBoxListData.text"
        :meeting="menuBoxListData.meeting"
        :online="menuBoxListData.online"
        :separatePrice="menuBoxListData.separatePrice"
        :price="menuBoxListData.price"
        :time="menuBoxListData.time"
        class="item"
      />
      <div class="pdf">
      <p class="note">※講座の詳細につきましては、以下の資料をご覧ください。</p>
      <p class="kakko">（タップ/クリックして詳細を確認できます。）</p>
      <div class="link">
        <div>
      <span>■</span><a href="https://healings-aime.com/menu/reiki.pdf" target="_blank" rel="noopener noreferrer">レイキ講座</a></div>
      <div>
      <span>■</span><a href="https://healings-aime.com/menu/healer.pdf
" target="_blank" rel="noopener noreferrer">ヒーラー養成講座</a>
      </div></div>
      </div>
  </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import SampleMenuMenuBox from "@/components/Sample/Menu/MenuBox.vue";

export default {
  name: "SampleMenu",
  components: {
    UiHeading,
    SampleMenuMenuBox,
  },
  props: {
    headingText: String,
    menuBoxList:Array
  },
  data() {
    return {
      users: "",
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cover {
  width: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: center;
.pdf{
  text-align: left;
  width: 80%;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  .note{
    font-size: 20px;
  }

  .link{
    margin-top: 30px;
    a{
      display: inline-block;
      color: #4e4a49;
      text-decoration: none;
      cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
    }
  }
}
}
</style>