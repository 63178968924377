<template>
  <div class="sample-access space-btm-120p">
    <UiHeading :h="'2'" :type="'E2'" :headingText="headingText" />

    <div class="how-to">
      <h3>オンラインセラピーのご利用方法</h3>
      <div class="text-img-cover">
        <p v-html="howToOnline" class="des"></p>
        <img alt="オンラインイメージ" src="@/assets/therapy-top-ira011.png" class="online-img"/>
      </div>
    </div>

    <div class="how-to-meet bd">
      <h3>対面セラピーのご利用方法</h3>
      <div class="text-img-cover-left">
        <p v-html="howToMeet" class="des"></p>
        <img alt="対面セラピーイメージ" src="@/assets/therapy-top-ira012.png" />
      </div>
    </div>

    <!-- <div class="access">
      <h3 class="bottom60 bd">アクセス</h3>
      <div class="access-box">
        <UiTable :tableList="addressTableList" class="table" />
        <div class="map-cover">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3252.0336327668765!2d136.74556611541828!3d35.404416880259696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6003af5968564ecb%3A0x5d5eb3c79be97b67!2z5qCq5byP5Lya56S-IOOCsOODrOOCpOODiOODmOODq-ODlw!5e0!3m2!1sja!2sjp!4v1659583167593!5m2!1sja!2sjp"
            width="100%"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>

          <div class="button">
            <a
              href="https://g.page/GREATHELP?share"
              target="_blank"
              rel="noopener noreferrer"
              >GoogleMapをみる</a
            >
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="about-visit">
      <h3 class="bd">訪問によるセラピーについて</h3>
      <div class="text-img-cover">
        <p v-html="aboutVisit" class="des"></p>
        <img
          alt="訪問セラピーイメージ"
          src="@/assets/therapy-top-ira012.png"
          class="home-img"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
// import UiTable from "@/components/Ui/Table.vue";

export default {
  name: "SampleFeature",
  components: {
    UiHeading,
    // UiTable,
  },
  props: {
    headingText: String,
    howToOnline: String,
    howToMeet: String,
    aboutVisit: String,
    addressTableList: Array,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.how-to,
.how-to-meet,
.access,
.about-visit {
  text-align: left;
  margin-bottom: 100px;
  h3 {
    font-size: 26px;
    text-align: center;
    padding-top: 100px;
    margin-bottom: 50px;
    font-family: var(--fontfamilySub);
    @media screen and (max-width: 767px) {
      font-size: 22px;
      padding-top: 20px;
    }
  }
  .bottom50 {
    margin-bottom: 50px;
    @media screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
  }
  .des {
    width: 920px;
    margin-right: auto;
    margin-left: auto;
    @media screen and (max-width: 920px) {
      width: 100%;
    }
  }
  .text-img-cover,
  .text-img-cover-left {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
    p {
      width: calc(90% - 150px);
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    img,
    .home-img {
      @media screen and (max-width: 767px) {
        width: 65%;
        margin-top: 30px;
      }
    }
    img,.online-img {
      width: 150px;
    }
    .home-img {
      width: 180px;
    }
  }
  .text-img-cover-left {
    flex-direction: row-reverse;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
}
.how-to {
  margin-top: -20px;
  margin-bottom: 100px;
  @media screen and (max-width: 767px) {
    margin-bottom: 60px;
  }
  h3 {
    margin-bottom: 50px;
    padding-top: 50px;
    @media screen and (max-width: 767px) {
      padding-top: 20px;
    }
  }
}
.how-to-meet {
  @media screen and (max-width: 767px){
    padding-bottom: 60px;
    margin-bottom: 0;
  }
  h3 {
    padding-top: 100px;
    @media screen and (max-width: 767px) {
      padding-top: 20px;
    }
  }
}
.about-visit {
  margin-bottom: 80px;
}
.access,
.about-visit{
  h3{
    @media screen and (max-width: 767px) {
      padding-top: 50px;
    }
  }
}

.access {
  .access-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 767px) {
      display: block;
    }
    .table {
      width: 48%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .map-cover {
      width: 48%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      .map {
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--black);
        p {
          font-size: 30px;
        }
      }
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        a {
          border: 1px solid var(--black);
          padding: 0 30px;
          margin: 20px 0;
          text-decoration: none;
          color: var(--black);
          &:hover {
            background-color: var(--sub);
            transition: 0.3s;
          }
        }
      }
    }
  }
}
.about-visit {
  text-align: left;
}
.space-btm-120p {
  padding-bottom: 120px;
  @media screen and (max-width: 767px) {
    padding-bottom: 50px;
  }
}
.bd {
  border-top: 4px double #f7f5f0;
  @media screen and (max-width: 767px) {
    padding-top: 30px;
  }
}
</style>