<template>
  <div class="sample">
    <div class="top">
      <img
        alt="ヒーリングサロンaimeレトップ"
        :src="require(`@/assets/${headerImgPathPc}`)"
        class="pc-only"
      />
      <img
        alt="ヒーリングサロンaimeトップSP"
        :src="require(`@/assets/${headerImgPathSp}`)"
        class="sp-only"
      />
    </div>

    <div class="space-top-70">
      <SampleAboutUs
        class="space-top-50"
        id="about"
        :headingText="`ヒーリングサロンaime<br class=sp-only>について`"
        :description="`ヒーリングサロンaime(アイム)では、心と身体のバランスを取り、健康に過ごしていくために必要なセラピーや座学を行っています。<br>今やストレス社会で、心や感情のバランスを崩して不調を抱えている方が多くいらっしゃいます。病気や不調の原因は心からのものが多く、「心の乱れ」へのアプローチが必要です。当サロンでは、セラピスト自身が大病を克服した経験を活かし、心と身体を整えるお手伝いをさせていただきます。病気という結果への対処ではなく、潜在意識に働きかけて心の奥深くに眠っている本当の気持ちや答えを見つけ、自分自身と向き合い、ご自身を癒していくことで、症状や不調の改善が可能です。<br>カウンセリングだけでも変化がある場合もございます。お気軽にご相談ください。`"
      />
    </div>

    <div class="space-top-70" style="background-color: var(--back01)">
      <SampleFeature
        class="space-top-80"
        :headingText="'ヒーリングサロン<br class=sp-only>aimeの特徴'"
        :featureBoxList="featureBoxList"
      />
    </div>

    <div class="space-top-70 wrap">
      <SampleUsers
        class="space-top-80"
        id="user"
        :headingText="`こんな人が利用しています`"
        :usersList="usersList"
      />
    </div>

    <div
      class="space-top-70 introduction-back"
      :class="{
        back01: path === '/sample01',
        back02: path === '/',
        back03: path === '/sample03',
        back04: path === '/sample04',
        back05: path === '/sample05',
        back06: path === '/sample06',
        back07: path === '/sample07',
        back08: path === '/sample08',
        back09: path === '/sample09',
        back10: path === '/sample10',
        back11: path === '/sample11',
        back12: path === '/sample12',
      }"
    >
      <SampleTherapistTypeB
        class="space-top-80"
        id="introduction"
        :headingText="'セラピストの紹介'"
        :imgPath="'therapy-top-hito_02.jpg'"
        :name="'Ai'"
        :roman="'アイ'"
        :description="'ヒーリングサロンaimeのホームページをご覧いただきありがとうございます。エンパス体質で、周囲に気を遣い、心に蓋をして明るく振る舞う幼年期。中学時代には摂食障害に悩まされることに。その後も、パニック障害、橋本病、瘦身と、度重なる大病に煩わされました。そんな経験を経て、自然療法・東洋医学・自律神経治療（分子栄養学、生化学や免疫理論とエレメント）・運動・食事法、とさまざまな治療を試み、生活環境を整えていく中で、心と身体の繋がりの深さに気付きます。遠回りしたからこそ、全てが有難く感じる、今。ご縁のある皆様が「自分の心に繋がり、ありのままの最高の自分を生きる」旅路のお供をすることが私の喜びです。'"
        :therapistTrouble="therapistTrouble"
        :therapistSkill="therapistSkill"

      />
    </div>

    <div class="space-top-70 wrap">
      <SampleMessage
        class="space-top-80"
        :headingText="`セラピストAiの歩みと想い`"
      />
    </div>

    <div class="space-top-70" style="background-color: var(--back02)">
      <SampleMenu
        class="space-top-80"
        id="menu"
        :headingText="'セラピーメニュー'"
        :menuBoxList="menuBoxList"
      />
    </div>

    <div class="space-top-70 wrap">
      <SampleAccess
        class="space-top-80"
        id="access"
        :headingText="`ご利用方法・アクセス`"
        :howToOnline="`ヒーリングサロンaimeではオンラインでヒプノセラピーセッションを行っております。<br>
セッションは「zoom」によるオンラインシステムを利用しています。
オンラインセッションの際はあらかじめzoomアプリのインストールをお願いいたします。<br><br>インターネット接続のある静かな環境でご参加ください。<br>マイク付きイヤホンまたはヘッドホンをご用意ください。<br>ご自身がリラックスできる姿勢が取れる場所からご参加ください。<br>また、技術的なサポートは行っておりませんのでご注意くださいませ。<br>＊ご自身の接続問題によるトラブルの責任はお受けいたしかねます。`"
        :howToMeet="`対面セッション・講座につきましては、吉祥寺近郊のレンタルスペースを使用しています。<br>ご予約が確定した時点で、場所のご案内をさせていただきます。`"
      />
    </div>

    <div class="space-top-70" style="background-color: var(--back01)">
      <SampleVoice
        class="space-top-80"
        id="voice"
        :headingText="'お客様の声'"
        :voiceBoxList="voiceBoxList"
      />
    </div>

    <div class="space-top-70 wrap">
      <SampleQa
        class="space-top-80"
        id="question"
        :qaBoxList="qaBoxList"
        :headingText="`よくある質問`"
      />
    </div>

    <div class="space-top-70" style="background-color: var(--back02)">
      <SampleFlow
        class="space-top-80"
        id="flow"
        :headingText="'ご予約から<br class=sp-only>セラピーまでの流れ'"
        :flowBoxList="flowBoxList"
      />
    </div>

    <div class="space-top-70 wrap">
      <SampleReserve
        class="space-top-80"
        id="contact"
        :headingText="`セラピーの<br class=sp-only>ご予約・お問い合わせ`"
      />
    </div>

    <!-- <div class="space-top-70" style="background-color: var(--back02)">
      <SampleInfo
        class="space-top-80"
        :headingText="'お知らせ'"
        :infoBoxList="infoBoxList"
      />
    </div>

    <div class="space-top-70 wrap">
      <SampleBlog
        class="space-top-80"
        :headingText="'ブログ'"
        :blogBoxList="blogBoxList"
      />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SampleAboutUs from "@/components/Sample/AboutUs.vue";
import SampleFeature from "@/components/Sample/Feature.vue";
import SampleUsers from "@/components/Sample/Users.vue";
import SampleTherapistTypeB from "@/components/Sample/TherapistTypeB.vue";
import SampleMessage from "@/components/Sample/Message.vue";
import SampleMenu from "@/components/Sample/Menu.vue";
import SampleAccess from "@/components/Sample/Access.vue";
import SampleVoice from "@/components/Sample/Voice.vue";
import SampleQa from "@/components/Sample/Qa.vue";
import SampleFlow from "@/components/Sample/Flow.vue";
import SampleReserve from "@/components/Sample/Reserve.vue";
// import SampleInfo from "@/components/Sample/Info.vue";
// import SampleBlog from "@/components/Sample/Blog.vue";
export default {
  name: "sample",
  components: {
    SampleAboutUs,
    SampleFeature,
    SampleUsers,
    SampleTherapistTypeB,
    SampleMessage,
    SampleMenu,
    SampleAccess,
    SampleVoice,
    SampleQa,
    SampleFlow,
    SampleReserve,
    // SampleInfo,
    // SampleBlog,
  },
  props: {
    headerImgPathPc: String,
    headerImgPathSp: String,
    path: String,
  },
  data() {
    return {
      featureBoxList: [
        {
          id: 1,
          imgPath: "therapy-top-tokutyou001.jpg",
          title: "自然に病気を治すチカラ",
          text: "あらゆる大病を克服した自らの経験から、西洋医学だけでは病気は治らないことを痛感しました。不調や症状の原因は内側から来ていることが多いです。身体と心の声を聞き、どうしたら改善していくのか。本来お持ちの自然治癒力を呼び覚まし、心身ともに健康に導くお手伝いを精一杯させていただきます。",
        },
        {
          id: 2,
          imgPath: "therapy-top-tokutyou002.jpg",
          title: "オンラインセッション",
          text: "子育て育児中のママ、お仕事が忙しい方、遠方や海外にお住まいの方にも、オンラインによるセッションと遠隔ヒーリングを20時まで受け付けています。お気軽にお問い合わせください。",
        },
        {
          id: 3,
          imgPath: "therapy-top-tokutyou003.jpg",
          title: "「自分を知ること」で健康に",
          text: "お客様のお悩みや症状に合わせた最適なセッションやセラピーをご提案させていただきます。ヒプノセラピーやレイキ講座、ヒーリング、そして心の癖を理解した上でのセラピー等もございます。とても深いお話をさせていただきますので、お客様とセラピストとの信頼関係が重要になります。そのため、初回のお時間は長めにいただいております。",
        },
        {
          id: 4,
          imgPath: "therapy-top-tokutyou004.jpg",
          title: "身体と心のバランスを<br class=sp-only>取るためのセラピー",
          text: "身体のサインに気付き、心の声が聞こえるようになると、身体と心のバランスが取れるようになります。徐々に身体の不調も改善していくと、自然と気力も湧いてくるようになり、自身の身体を信頼できるようにもなります。身体は至ってシンプル。そして決して身体は間違えません。自分自身を信頼できる自分作りが目標。そのようなセラピーを提供しています。",
        },
        {
          id: 5,
          imgPath: "therapy-top-tokutyou005.jpg",
          title: "自分を癒し、周りも癒す",
          text: "当サロンでは、講座メニューの提供も行っています。私たちは、潜在意識に繋がり、自分と向き合うことで、本来持ち合わせた才能に気付き、覚醒を進めていくことが可能です。これからはセルフヒーリングの時代となっていきます。講座を受講することで、基本的なヒーリングができるようになります。周りの人を癒したい、レイキやヒーラーにご興味がある方もお気軽にご相談ください。更なる飛躍のお手伝いをさせていただきます。",
        },
      ],
      usersList: [
        {
          id: 1,
          users: "健康になりたい",
        },
        {
          id: 2,
          users: "トラウマ・<br class=pc-sp-only-users>ブロックがある",
        },
        {
          id: 3,
          users: "不安や心配ばかりしてしまう",
        },
        {
          id: 4,
          users:
            "自信を持ちたい・<br class=pc-sp-only-users>ポジティブ思考になりたい",
        },
        {
          id: 5,
          users: "人間関係を良くしたい",
        },
        {
          id: 6,
          users:
            "パニック障害・摂食障害・<br class=pc-sp-only-users>PTSD・うつ病",
        },
        {
          id: 7,
          users: "今世の目的や課題・<br class=pc-sp-only-users>使命を知りたい",
        },
        {
          id: 8,
          users: "癒されたい",
        },
        {
          id: 9,
          users:
            "ヒーラーのメンテナンス・<br class=pc-sp-only-users>ヒーラー志望の方",
        },
      ],
      therapistTrouble: [
        {
          id: 1,
          case: "健康",
        },
        {
          id: 2,
          case: "トラウマ",
        },
        {
          id: 3,
          case: "自信",
        },
        {
          id: 4,
          case: "人間不信",
        },
        {
          id: 5,
          case: "パニック障",
        },
        {
          id: 6,
          case: "摂食障害",
        },
        {
          id: 7,
          case: "癒し",
        },
        {
          id: 8,
          case: "願望実現",
        },
        {
          id: 9,
          case: "不安",
        },
      ],
      therapistSkill:[
      {
          id: 1,
          case: "NGH米国催眠士協会ヒプノセラピスト",
        },
        {
          id: 2,
          case: "ABH米国催眠療法協会マスターヒプノティスト",
        },
        {
          id: 3,
          case: "臼井式レイキマスター",
        },
        {
          id: 4,
          case: "臼井式直傳靈氣ヒーラー",
        },
        {
          id: 5,
          case: "蜂蜜療法協会　蜂蜜療法家",
        },
        {
          id: 6,
          case: "蜂蜜療法協会　ハニーセラピスト",
        },
        {
          id: 7,
          case: "accessbars practitioner<br>(アクセスバーズプラクティショナー)",
        },
        {
          id: 8,
          case: "access consciousness body<br>(アクセスボディプロセスプラクティショナー)",
        },
        {
          id: 9,
          case: "ICGT認定クリスタルヒーラー",
        },
      ],
      menuBoxList: [
        {
          id: 1,
          title: "カウンセリング 30分",
          meeting: false,
          online: true,
          price: 4400,
          separatePrice: 1,
          time: "30分",
          text: "頭の中がまとまらず話をして整理をされたい方、症状が複数ある場合で悩まれている方、カウンセリングを受けてから決めたい方、セッション後の感情について相談されたい方…お気軽にご相談ください。お電話での対応もいたしております。<br>※30分を超えましたら、10分毎に1,000円(税込)となります。",
        },
        // {
        //   id: 1,
        //   title: "アクセスバーズ 30分",
        //   meeting: true,
        //   online: false,
        //   price: 5000,
        //   separatePrice: false,
        //   time: "30分",
        //   text: "脳内クリーニング、脳のデトックスと言われているアクセスバーズ。エステサロン、診療内科や歯科でも導入されています。頭部にある32箇所のポイントを優しくタッチすることで脳内に溜まっているエナジーの解放をするセラピーです。",
        // },
        // {
        //   id: 1,
        //   title: "【女性限定】体験ヒーリング",
        //   meeting: false,
        //   online: true,
        //   price: 5500,
        //   separatePrice: false,
        //   time: "50分",
        //   text: "こちらは、当サロンaimeを初めてご予約される方限定の体験メニューです。何かの問題にアプローチするセラピーではなく、催眠状態を体験していただく中で、潜在意識に繋がり、気分転換やリラクゼーションを感じていただくメニューになります。遠隔レイキヒーリングで身体の調整もさせていただきます。",
        // },
        {
          id: 2,
          title: "自己改革、身体の調整３ヶ月プログラム",
          meeting: false,
          online: true,
          price: 225000,
          separatePrice: 1,
          time: "遠隔調整ヒーリング60分3日連続、Zoomセッションorアクセスバーズor霊気or講座×2回、カウンセリング２回　計7回×3(月数)",
          text: "身体の状態をヒーリングを用いて見させていただき、ご本人に必要な改善方法を提案させていただきます。心の問題にもアプローチしていき、身体と心のバランスを整えていきます。ご本人しか自身を変えることは出来ませんので、本気で改善されたい方のみお受けさせていただきます。<br>現在、通院されてる方は場合によりお受けできないこともございます。ご了承くださいませ。",
        },
        // {
        //   id: 2,
        //   title: "アクセスバーズ 60分",
        //   meeting: true,
        //   online: false,
        //   price: 10000,
        //   separatePrice: false,
        //   time: "60分",
        //   text: "脳内に蓄積されている不要な思考、信念、感情、情報、制限、ストレスなどを解放します。ベータ波より更にリラックスしているシータ波になると言われています。30分よりも更にじっくりと溜め込んだものを解放をさせていきます。",
        // },
        // {
        //   id: 2,
        //   title: "レイキヒーリング30",
        //   meeting: false,
        //   online: true,
        //   price: 4000,
        //   separatePrice: false,
        //   time: "30分",
        //   text: "気の滞りを改善して、不調和な箇所を調整し、自然治癒力や免疫力を高めてメンタルにも活力を与えていきます。不安や心の問題にもアプローチします。流して欲しい時間をあらかじめ伺わせていただき、リラックスできる環境で受けていただきます。",
        // },
        {
          id: 3,
          title: "遠隔レイキヒーリング 30分",
          meeting: false,
          online: true,
          price: 5000,
          separatePrice: 1,
          time: "30分",
          text: "気の滞りを改善して、不調和な箇所を調整し、自然治癒力や免疫力を高めてメンタルにも活力を与えていきます。<br>不安や心の問題にもアプローチします。流して欲しい時間をあらかじめ伺わせていただき、リラックスできる環境で受けていただきます。",
        },
        // {
        //   id: 3,
        //   title: "心の癖セラピー（直傳靈氣）",
        //   meeting: true,
        //   online: true,
        //   price: "(初回)5,000円・(2回目以降)4,000円",
        //   separatePrice: true,
        //   time: "30分",
        //   text: "表面化した心の癖・潜在化した心の癖にアプローチします。<br>●表面化したもの…アルコール依存・過度の喫煙・強い怒り・執着・万引き・過食摂食・強迫性障害など(行動や感情に表面化しているもの、本人が簡単に自覚しやすい）<br>●潜在化したもの…うつ・パニック障害・適応障害・自信がない・全ての心身症など(本人でも自覚していないケースもある→潜在化したものを明らかにし、本人が自覚していく）<br>お電話でも可能です。",
        // },
        {
          id: 4,
          title: "遠隔レイキヒーリング 60分",
          meeting: false,
          online: true,
          price: 10000,
          separatePrice: 1,
          time: "60分",
          text: "特定の症状、疾患がある方は長めの60分を受けていただくことを推奨しております。30分と同様にリラックスできる環境で受けていただきます。好転反応がでることがありますが、その際は水分を取っていただきゆっくり過ごしていただきます。改善していく過程なのでご安心ください。",
        },
        // {
        //   id: 4,
        //   title: "カラダのメンテナンス(レベル１)",
        //   meeting: true,
        //   online: true,
        //   price: "33000",
        //   separatePrice: false,
        //   time: "(1日目)2時間30分・(2日目ヒプノセラピー)1時間30分～2時間",
        //   text: "身体へのヒーリングを学ぶ座学とアチューメントになります。こちらを受けていただきますと、レイキファーストディグリーの資格も同時に取得できます。<br>セルフヒーリングを通して浮き上がってきた感情や課題に、ヒプノセラピーセッションでさらに向き合っていき、解放してきます。期間を空けてヒプノセラピーを1回行う、計2日間のセラピーになります。<br>ヒプノセラピーはオンラインセッションとなります。",
        // },
        {
          id: 5,
          title: "身体の調整　レイキヒーリング×3日間",
          meeting: false,
          online: true,
          price: 25000,
          separatePrice: 1,
          time: "60分×3日連続",
          text: "何かの症状が出ている場合は3日続けて受けていただくことで、デトックスも起きやすく改善の方向に早く導いていくことができます。内臓の疲労具合にもよりますので身体の状態とご希望を伺ってからになります。",
        },
        // {
        //   id: 5,
        //   title: "ココロと繋がる(レベル２)",
        //   meeting: true,
        //   online: true,
        //   price: 44000,
        //   separatePrice: false,
        //   time: "(1日目)3時間・(2日目ヒプノセラピー)1時間30分～2時間",
        //   text: "心の声を読み取れるように、自分の本質と繋がるための座学とアチューンメント、そしてヒプノセラピーのセッションを1回受けていただき、さらに深い学びと心身の解放に繋げて、自身を理解していきます。なお、こちらを受けていただきますと、レイキセカンドディグリーの資格も同時に取得できます。<br>ヒプノセラピーセッションにつきましては、少し日数を空けてのオンラインセッションになります。レベル１を習得された方が対象となります。",
        // },
        {
          id: 6,
          title: "心の癖セラピー(直傳霊気)",
          meeting: true,
          online: true,
          price: "(初回)5,000円・(２回目以降)4,000円",
          separatePrice: 0,
          time: "30分",
          text: "表面化した心の癖・潜在化した心の癖にアプローチします。<br>●表面化したもの…アルコール依存・過度の喫煙・強怒り・執着・過食拒食・脅迫性障害など(行動や感情に表面化しているもの、本人が自覚しやすい)<br>●潜在化したもの…うつ・パニック障害・自信がない・全ての心身症など(本人でも自覚していないケースもある→潜在化したものを明らかにし、本人が自覚していく)<br>お電話でも可能なセッションです。",
        },
        // {
        //   id: 6,
        //   title: "五感と第六感を鍛えて精神的覚醒へ(レベル３)",
        //   meeting: true,
        //   online: true,
        //   price: 44000,
        //   separatePrice: false,
        //   time: "(1日目)2時間30分～3時間・(2日目ヒプノセラピー)1時間30分～2時間",
        //   text: "受容性を高め、メッセージを受け取りやすくし、直感から導きが得られ、良い方向が見出せます。それらを活性化するテクニックを習得していただきます。「自分がなぜここに存在しているのか」気付きが得られ、自覚の上に立ち、果たす役割が明瞭となり、本来の自己に目覚め、真我へと導かれます。こちらを受けていただきますと、レイキサードディグリーの資格も同時に取得できます。<br>オンラインヒプノセッションも1回受けていただきます。レベル１・２を習得された方が対象となります。",
        // },
        {
          id: 7,
          title: "ヒプノセラピー(初回)",
          meeting: false,
          online: true,
          price: 25000,
          separatePrice: 1,
          time: "2時間30分～3時間",
          text: "催眠を利用して潜在意識にダイレクトにアクセスすることで問題の原因を見付け、イメージや退行テクニック・暗示を用いて開放していく心理療法です。ヒプノセラピーは悩んでいることへの「答え」を引き出すのが得意なセラピーです。いろいろ試して努力しているのに、なかなか変化が現れない…そのような場合は、潜在意識下に眠っている抑圧された感情を探して呼び起こし、開放していくことで、より良い状態にすることができます。",
        },
        {
          id: 8,
          title: "ヒプノセラピー(2回目以降)",
          meeting: false,
          online: true,
          price: 22000,
          separatePrice: 1,
          time: "1時間30分～2時間",
          text: "ヒーリングサロンaimeでヒプノセラピーのご利用が2回目以降となる方を対象としたセラピーです。前回のセラピーに続いて90分間のセラピーを行います。",
        },
        {
          id: 9,
          title: "アクセスバーズセッション",
          meeting: true,
          online: false,
          price: "5,000円<span>(税込)</span>/30分・10,000円<span>(税込)</span>/60分・15,000円<span>(税込)</span>/90分",
          separatePrice: 2,
          // time: ,
          text: "脳内クリーニング、脳の断捨離と言われているアクセスバーズ。エステサロン、心療内科や歯科でも導入されています。<br>頭部にある32箇所のポイントを優しくタッチすることで脳内に溜まっているエナジーの解放をするセラピーです。ベータ波より更にリラックスしているシータ波になると言われています。60分90分コースは更に深いものが解放されます。この素晴らしい感覚を体感されてみてくださいませ。",
        },
        // {
        //   id: 9,
        //   title: "カウンセリングコース",
        //   meeting: false,
        //   online: true,
        //   price: 4000,
        //   separatePrice: false,
        //   time: "30分まで",
        //   text: "頭の中がまとまらず話して整理をされたい方、まずはカウンセリングを受けてから決めたい方、セッション後に上がってきた感情について相談されたい方…などお気軽にご連絡ください。zoomだけでなく、お電話での対応もいたしております。ヒプノセラピーセッションは行いません。<br>※30分を超えましたら、10分毎に1,000円(税込)となります。なお、延長は10分単位で可能です。",
        // },
        {
          id: 10,
          title: "【講座】レイキ ファースト",
          meeting: true,
          online: false,
          price: 21000,
          separatePrice: 1,
          time: "2時間30分",
          text: "レイキの歴史、基礎知識、手の当て方などを学んでいただきます。回路を開くアチューンメントを受けますと、その日からレイキヒーリングができるようになります。",
        },
        {
          id: 11,
          title: "【講座】レイキ セカンド",
          meeting: true,
          online: false,
          price: 25000,
          separatePrice: 1,
          time: "3時間",
          text: "セカンドでは、３つのシンボルとマントラを伝授します。ファーストで開かれた回路から流れるエネルギーがパワーアップし、より肉体・感情・精神を癒し、遠隔ヒーリングもできるようになり、ヒーリング能力がグレードアップします。",
        },
        {
          id: 12,
          title: "【講座】レイキ サード",
          meeting: true,
          online: true,
          price: "(対面)25,000円・(オンライン)22,000円",
          separatePrice: 0,
          time: "3時間",
          text: "サードは、レイキ法の最高レベル「マスターシンボル」の伝授により、ヒーリングを超えた自己成長・自身の霊性を向上させることを目的としています。受容性を高め、メッセージを受け取りやすくし、直感力にも磨きをかけていき、精神性を高めて、真の自己実現を目指します。",
        },
        {
          id: 13,
          title: "【講座】レイキ マスター<br class=sp-only>（ティーチャー）",
          meeting: true,
          online: false,
          price: 47000,
          separatePrice: 1,
          time: "3時間",
          text: "レイキを教えるマスターになるための講座になります。マスターとしての心構えやアチューンメント法を学んでいただきます。",
        },
        {
          id: 14,
          title: "クリスタルヒーリング",
          meeting: true,
          online: true,
          price: "18,000円",
          separatePrice: 0,
          time: "90～120分",
          text: "クリスタル(天然石)を使い、チャクラ・オーラ・経路などのエネルギー体を調整するヒーリング法です。クリスタルは固有のエネルギー(波動・周波数)を持っており結晶構造が純粋であることから人間に比べて安定した周波数を保っていることや、種類が豊富で各々特有のエネルギーを持っているため、組み合わせることで幅広いエネルギー調整が行えます。<br>＊CWJクリスタルヒーリングコース<br>テキスト参照",
        },
        // {
        //   id: 14,
        //   title: "レイキヒーリング",
        //   meeting: false,
        //   online: true,
        //   price: 4000,
        //   separatePrice: false,
        //   time: "30分",
        //   text: "気の滞りを改善して、不調和な箇所を調整し、自然治癒力や免疫力を高めてメンタルにも活力を与えていきます。不安や心の問題にもアプローチします。流して欲しい時間をあらかじめ伺わせていただき、リラックスできる環境で受けていただきます。",
        // },
      ],
      // addressTableList: [
      //   {
      //     id: 1,
      //     th: "郵便番号",
      //     td: "450-0046",
      //   },
      //   {
      //     id: 2,
      //     th: "住所",
      //     td: "愛知県名古屋市中区栄2-5 パークハイツビル202",
      //   },
      //   {
      //     id: 3,
      //     th: "経路案内",
      //     td: "名古屋駅から地下鉄東山線で藤が丘方面へ2駅。栄駅で下車します。10番出口を出てすぐ左手に吉野家が入った建物があります。その建物の2階です。",
      //   },
      // ],
      voiceBoxList: [
        {
          id: 1,
          imgPath: "therapy-top-ira002.png",
          voice:
            "レイキヒーリングについて疑問が出た時もとても丁寧に教えてくださり、何の迷いも不安もなくヒーリングを実践することができ、今では自分や家族を中心にレイキヒーリングを行うことでレイキのエネルギーをいただき、癒されています。",
          man: "30代 女性",
        },
        {
          id: 2,
          imgPath: "therapy-top-ira003.png",
          voice:
            "あい先生のレイキを何度か受けました。いつも身体のどこかに痛みがあったり、気持ちが弱くなっている時にお願いしています。流してる間は分からないのですが、気付くといつの間にか痛み和らいだり、気分が軽くなったりします。不思議と心も身体も温かい穏やかな気持ちになります。病院では中々治らなかったりするので本当に不思議です。心も身体もほぐしてくれる素敵な先生です。",
          man: "40代 sさん",
        },
        {
          id: 3,
          imgPath: "therapy-top-ira001.png",
          voice:
            "私の起業に関して「結果が出せなかったらどうしよう」と言う不安感があり、あいさんに相談しました。あいさんとお話をしているうちに「敗北感」という言葉が何度も出てきて、自分でも気付かなかった本音や過去に感じた「敗北感」が浮き上がってきました。過去の体験に起因していたなんて驚き、衝撃でした。過去に受け取れなかった母からの沢山の愛情を受けとり、安心感に包まれたことでこの人生で望んでるものを思い出しました。",
          man: "40代 N.A",
        },
        {
          id: 4,
          imgPath: "therapy-top-ira004.png",
          voice:
            "素晴らしいヒプノセラピーのセッションをありがとうございました。お金に対する心配ばかしていたのが前世からのものだとわかり、腑に落ちました。セッション中はとても気持ちが良くて寝てしまいそうになるくらいでした。リフレッシュもできまして、とても気持ちの良い時間を体験しました。",
          man: "60代 女性",
        },
        {
          id: 5,
          imgPath: "therapy-top-ira005.png",
          voice:
            "催眠療法は初めての体験でしたが、前世での母との関係に涙が溢れて止まりませんでした。これほど涙が出たことにとも驚き、セラピーが終わった時には胸の辺りが熱くなり、安堵していて気持ちも不思議な位にスッキリとしていました。一つ、自分の気持ちがハッキリしたことがあり、改めて再確認できました。また是非受けてみたいです。",
          man: "50代 女性",
        },
        {
          id: 6,
          imgPath: "therapy-top-ira006.png",
          voice:
            "遠隔でヒーリングを送っていただきました。不安で押しつぶられそうでしたが、大丈夫だと思えてお腹とハートチャクラの辺りがポカポカしました。温かいエネルギーにホッとして仕事に行くことができました。あいさん、ありがとうございました。",
          man: "30代 女性",
        },
        {
          id: 7,
          imgPath: "therapy-top-ira007.png",
          voice:
            "レイキの歴史やヒーリング方法もさることながら、レイキヒーリングを実践するというワークも、不思議なくらいにエネルギーを感じてとても気持ちよかったです。アチューメントを行っていただいた際にも、自分の前世の映像を見たり、宇宙の根源の映像を見るなど驚きの連続でした。この世界は本当に宇宙からたくさんの愛や恩恵を受けているのだと、心の底から実感できて、アチューメントの時に泣いてしまいました。",
          man: "20代 女性",
        },
        {
          id: 8,
          imgPath: "therapy-top-ira009.png",
          voice:
            "あいさん、この度はレイキ講座を受講させていただきありがとうございました。<br>元々レイキには興味があり、どのような内容なのか受講する前からわくわくしていたのですが、予想をはるかに超える内容でした。自分自身に眠るエネルギーやパワーも再認識して、スピリチュアルに関わる者として自信を持つことができました。あいさんの講座を受けるだけでヒーリングされているような感覚でした。",
          man: "20代 Rさん",
        },
        {
          id: 9,
          imgPath: "therapy-top-ira010.png",
          voice:
            "あいさんにレイキを教えて頂いてとても心が救われました。心が温かくなり幸せな気持ちになれました。自分も何か人にしてあげられることが増えるという事実が本当に嬉しかったです。レイキという手から繋がるさまざまな愛を感じられました。",
          man: "40代 fさん",
        },
        {
          id: 10,
          imgPath: "therapy-top-ira008.png",
          voice:
            "ここ一ヶ月くらい、死の淵をさまようような状態でしたが、あいさんのレイキヒーリングを受けて生き返ると言いますか、この世に戻ってきた感覚です。すごく眠くなりまして、椅子から落ちそうになりました。助かりました。タイミングよく受けられて助かりました。",
          man: "50代 女性",
        },
      ],
      qaBoxList: [
        {
          id: 1,
          qestion: "催眠状態から意識がなくなることはありますか？",
          answer:
            "いわゆる意識と無意識が繋がった状態で、心地の良いリラックスした状態です。意識はハッキリしており、マインドコントロールされるようなことは一切ございませんのでご安心ください。",
        },
        {
          id: 2,
          qestion: "催眠状態から抜けられなくなりませんか？",
          answer:
            "そのようなことはございませんのでご安心ください。当サロンではセッションの終了時に覚醒誘導のステップを踏ませていただきます。催眠に入ったり、抜けたりすることは、ご自身でコントロールできます。",
        },
        {
          id: 3,
          qestion: "何回通えば良いですか？一回で効果はありますか？",
          answer:
            "必要だと思うタイミングで、好きな時に受けていただくのがベストです。<br>ヒプノセラピーやレイキヒーリングは、その方がお持ちの問題や悩みにもよりますが、一回のセッションで効果や変化を感じられる方もいらっしゃいます。問題や悩みを抱えてきた期間、性格やトラウマ、環境や課題など多くの要素が含まれるため、個人差があります。必ず続けて受けなくてはいけないということではございませんので、ご安心ください。",
        },
        {
          id: 4,
          qestion: "前世療法では必ず前世が見れますか？どう感じるのですか？",
          answer:
            "ヒプノセラピーでは前世の体験を確約することはできません。何かが「見える」と言う方もいれば、「聞こえる」「感じる」「イメージが浮かぶ」と言う方もいて、人それぞれです。感じたことをそのまま見ていけば、それが最も必要な体験やメッセージです。",
        },
        {
          id: 5,
          qestion: "霊感がなくてもレイキはできるようになりますか？",
          answer:
            "レイキは誰にでも流れているもので、誰にでもできます。霊感は関係ありませんのでご安心ください。",
        },
        {
          id: 6,
          qestion: "他のヒーリングと迷っています。レイキは何が良いのですか？",
          answer:
            "レイキは意図のないポジティブな自然エネルギーなので意識をして出す必要もなく、体力・気力・精神力も必要ありません。相手にレイキをすると、自身にも流れて良い作用があります。",
        },
        {
          id: 7,
          qestion:
            "ヒーラーとして働きたいわけではないけれど、レイキの講座を受けても大丈夫ですか？",
          answer:
            "もちろんです。自身だけでなく家族や周りの友人にも気軽にヒーリングをされたいという方も多いです。",
        },
      ],
      flowBoxList: [
        {
          id: 1,
          no: "01",
          title: "まずはご予約のお問い合わせから",
          text: "LINEもしくはメールでのお問い合わせから、ご希望のメニューとお日にちをお知らせください。<br>予約システムからもご予約いただけます。",
          yazirushi: true,
        },
        {
          id: 2,
          no: "02",
          title: "ご予約日時の決定",
          text: "こちらから折り返しご連絡をし、ご予約の日程を調整します。日程が決まり次第、当日までにご準備をお願いしたい内容をお知らせします。ご不明な点がございましたらお気軽にお申し付けください。",
          yazirushi: true,
        },
        {
          id: 3,
          no: "03",
          title: "ご予約の3日前にリマインドメッセージの確認",
          text: "ご予約日の3日前になりましたら、こちらからリマインドメッセージをお送りいたします。これ以降のキャンセルにつきましては、キャンセル料が発生いたしますのでご注意ください。<br><br class=sp-only>１．ご予約日の3日前まで…無料<br>２．2日前…………料金の50%<br>３．前日…………&ensp;料金の80%<br>４．当日…………料金の100%",
          yazirushi: true,
        },
        {
          id: 4,
          no: "04",
          title: "ご予約当日にセッションを受ける",
          text: "対面の場合はご指定の場所にて、オンラインの場合は事前にzoomの招待URLを送らせていただきます。",
          yazirushi: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  //   created() {
  // var VueScrollTo = require('vue-scrollto');

  // var options = {
  //     container: '#container',
  //     easing: 'ease-in',
  //     lazy: false,
  //     offset: -60,
  //     force: true,
  //     cancelable: true,
  //     onStart: function(element) {
  //       console.log(element);
  //       // scrolling started
  //     },
  //     onDone: function(element) {
  //       console.log(element);
  //       // scrolling is done
  //     },
  //     onCancel: function() {
  //       // scrolling has been interrupted
  //     },
  //     x: false,
  //     y: true
  // }

  // var cancelScroll = VueScrollTo.scrollTo("about", 500, options)
  // console.log(cancelScroll);
  //   },
  // mounted() {
  //   this.$nextTick(() => {
  //     if (this.$route.hash !== "") {
  //       setTimeout(function(){
  //         const hash = this.$route.hash;
  //         alert('移動するよ！');
  //         this.$scrollTo(hash, 0, {});
  //       }.bind(this), 1000,);
  //     }
  //   });
  // },
  //   mounted() {
  //   this.$nextTick(() => {
  //     if (this.$route.hash !== "") {
  //       // alert('hashある！');
  //       setTimeout(function(){
  //         const hash = this.$route.hash;
  //         console.log("koko");
  //         this.$scrollTo(hash, 0, {});
  //       }.bind(this), 1500,);
  //     }else {
  //       // alert('hashない！');
  //     }
  //   });
  // },
  created() {
    this.$nextTick(() => {
      this.OriginalVueScrollTo(this.$route.query.position);
    });
  },
  methods: {
    OriginalVueScrollTo(id) {
      //
      let element = "#" + id;
      let VueScrollTo = require("vue-scrollto");

      //
      let duration = 0; // 実行までの時間（ms）

      //
      let option = {
        offset: -90, // ゴール地点からどれだけズレた位置に飛ぶか
      };

      // scroll実行
      VueScrollTo.scrollTo(element, duration, option);
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500;700&family=Noto+Serif+JP:wght@400;700&display=swap");
.sample {
  font-family: var(--fontfamilyMain);
}
.top {
  img {
    vertical-align: top;
    width: 100vw;
  }
}
.back01 {
  background-image: url(~@/assets/therapy-top-hito-back_01.jpg);
}
.back02 {
  background-image: url(~@/assets/therapy-top-hito-back_02.jpg);
}
.back03 {
  background-image: url(~@/assets/therapy-top-hito-back_03.webp);
}
.back04 {
  background-image: url(~@/assets/therapy-top-hito-back_04.webp);
}
.back05 {
  background-image: url(~@/assets/therapy-top-hito-back_05.webp);
}
.back06 {
  background-image: url(~@/assets/therapy-top-hito-back_06.webp);
}
.back07 {
  background-image: url(~@/assets/therapy-top-hito-back_07.webp);
}
.back08 {
  background-image: url(~@/assets/therapy-top-hito-back_08.webp);
}
.back09 {
  background-image: url(~@/assets/therapy-top-hito-back_09.webp);
}
.back10 {
  background-image: url(~@/assets/therapy-top-hito-back_10.webp);
}
.back11 {
  background-image: url(~@/assets/therapy-top-hito-back_11.webp);
}
.back12 {
  background-image: url(~@/assets/therapy-top-hito-back_12.webp);
}
.introduction-back {
  background-size: cover;
  background-repeat: no-repeat;
    background-position: 100% 50%;
  @media screen and (max-width: 767px) {
    background-position: 48% 50%;
  }
}
.space-top-50 {
  margin-top: 50px;
}

</style>